<template>
  <div class="botAd">
    <p class="botAd-txt">还有其他项目疑问？专家一对一解答！</p>
    <p class="botAd-txt">提高申报成功率</p>
    <span class="botAd-btn" @click="checkFn(true)">点击免费咨询</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapMutations } from 'vuex'

export default defineComponent({
  setup() {
    return {
      ...mapMutations({
        checkFn: 'changeSideBarOnlineStatus'
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.botAd {
  background: #25242a url('~assets/images/searchResult/bot_bg.jpg') no-repeat
    center/cover;
  height: 397px;
  padding-top: 80px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-txt {
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 42px;
    color: #ffffff;
    padding-bottom: 15px;
  }
  &-btn {
    margin-top: 17px;
    width: 323px;
    height: 72px;
    line-height: 72px;
    text-align: center;
    background: linear-gradient(90deg, #f4d1b1 0%, #cda97a 100%);
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    user-select: none;
  }
}
</style>
