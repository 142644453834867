
import BaseItem from '@/common/baseItem.vue'
import HotList from '@/common/hotList.vue'
import Pagination from '@/common/pagination.vue'

import {
  policySearch,
  searchLabel,
  tenderSearch,
  heat
} from '@/api/searchResult'
import {
  defineComponent,
  onBeforeMount,
  ref,
  computed,
  onActivated,
  nextTick
  // getCurrentInstance
} from 'vue'
import {
  useRoute,
  onBeforeRouteUpdate,
  useRouter,
  onBeforeRouteLeave
} from 'vue-router'
import { ZcSearchLabel } from '@/types/searchResult/index'
import { PageData } from '@/types/index'
import CheckLine from './components/checkLine.vue'
import NoData from './components/noData.vue'
import BotAd from './components/botAd.vue'
import BxItem from './components/bxItem.vue'
import CommonTabs from '@/common/CommonTabs/index.vue'
import CommonTabsItem from '@/common/CommonTabs/CommonTabsItem/index.vue'
import { useStore } from 'vuex'

const bbSearchLabel = {
  titleArray: ['业务区域', '全部行业', '全部类型', '发布时间'],
  titleSource: ['业务区域', '全部行业', '全部类型', '发布时间'],
  // segment
  areaList: [
    // { title: '全部区域', key: '', status: false, check: false },
    { title: '深圳市', key: '4403', status: false, check: false },
    { title: '宝安区', key: '440306', status: false, check: false },
    { title: '龙岗区', key: '440307', status: false, check: false },
    { title: '罗湖区', key: '440303', status: false, check: false },
    { title: '南山区', key: '440305', status: false, check: false },
    { title: '福田区', key: '440304', status: false, check: false },
    { title: '盐田区', key: '440308', status: false, check: false },
    { title: '龙华区', key: '440311', status: false, check: false },
    { title: '光明区', key: '440310', status: false, check: false },
    { title: '坪山区', key: '440309', status: false, check: false },
    { title: '大鹏新区', key: '440312', status: false, check: false }
  ],
  industryList: [
    // { key: '', status: true, title: '全部' },
    { key: '货物', status: false, title: '货物' },
    { key: '服务', status: false, title: '服务' },
    { key: '工程', status: false, title: '工程' },
    { key: '其他', status: false, title: '其他' }
  ],
  typeList: [
    // { key: '', status: true, title: '全部' },
    { key: '1001', status: false, title: '招标公示' },
    { key: '1002', status: false, title: '中标公示' },
    { key: '1003', status: false, title: '变更公示' },
    { key: '1004', status: false, title: '拟在建' },
    { key: '1005', status: false, title: '其他公示结果' }
  ],
  releaseTimeList: [
    // { key: '', title: '不限', status: true },
    { key: '1', title: '3日内', status: false },
    { key: '2', title: '7日内', status: false },
    { key: '3', title: '1个月内', status: false },
    { key: '4', title: '3个月内', status: false }
  ],
  tenderPublicList: [
    { key: '1001', title: '全部', status: true },
    { key: '2001', title: '公开招标', status: false },
    { key: '2004', title: '单一来源', status: false },
    { key: '2003', title: '竞争性谈判', status: false },
    { key: '2002', title: '邀请招标', status: false },
    { key: '2005', title: '询价', status: false }
  ]
}

export default defineComponent({
  name: 'searchResult',
  components: {
    BaseItem,
    HotList,
    CheckLine,
    Pagination,
    NoData,
    BotAd,
    BxItem,
    CommonTabs,
    CommonTabsItem
  },
  setup() {
    const store: any = useStore()
    const router: any = useRouter()
    const route: any = useRoute()
    // const instance: any = getCurrentInstance()
    const page = ref<PageData>({ currentPageNum: 1, recordsPerPage: 10 })
    const keyword = ref('')
    const keywordSearch = ref('')
    const activeName = ref<string>('1')
    const subActiveName = ref<string>('1')
    const searchTotal = ref(0)
    const zcqyList = ref([])
    const zcCheckList = ref<Array<number>>([])
    const typeCheckList = ref<Array<number | string>>([])
    const productCheckList = ref<Array<number | string>>([])
    const zcSearchLabel = ref<ZcSearchLabel>({
      area_list: [],
      label_list: []
    })
    //  标讯
    const bbPage = ref<PageData>({ currentPageNum: 1, recordsPerPage: 10 })
    const bbSearchTotal = ref(0)
    const bbList = ref([]) // 标讯列表
    const bbSLabel = ref(bbSearchLabel)
    const bbSVInit = {
      areaList: [],
      industryList: '',
      typeList: '',
      releaseTimeList: ''
    }
    const bbSValue = ref(bbSVInit)

    const hotList = ref([])

    const zcLabel = computed(() => {
      if (searchTotal.value > 999) {
        return '999+'
      }
      return searchTotal.value
    })

    const bbLabel = computed(() => {
      if (bbSearchTotal.value > 999) {
        return '999+'
      }
      return bbSearchTotal.value
    })

    const setKeywordSearch = () => {
      if (keyword.value && keyword.value !== '') {
        const v = keyword.value
        keywordSearch.value = v
      } else {
        keywordSearch.value = ''
      }
    }

    const searchFn = () => {
      let typeCheckListValue = typeCheckList.value
      let productCheckListValue = productCheckList.value
      if (subActiveName.value !== '1') {
        typeCheckListValue = []
        productCheckListValue = []
      }
      const classCode = [...typeCheckListValue, ...productCheckListValue].filter(item => item).join(',')
      const params = Object.assign(
        {},
        { keyword: keyword.value || '' },
        {
          areaId: zcCheckList.value.join(','),
          classCode: classCode,
          class: subActiveName.value
        },
        {
          paginate: page.value
        }
      )
      policySearch(params).then((res: any) => {
        if (res.data) {
          searchTotal.value = res.data.total
          zcqyList.value = res.data.data
          setKeywordSearch()
        }
      })
    }

    const isParamsChange = computed(() => {
      const {
        areaList,
        releaseTimeList,
        industryList,
        typeList
      } = bbSValue.value
      if (activeName.value === '1') {
        return zcCheckList.value.length > 0 || typeCheckList.value.length > 0 || productCheckList.value.length > 0
      } else {
        return (
          areaList.length > 0 || releaseTimeList || industryList || typeList
        )
      }
    })

    const tenderSearchFn = () => {
      const params = Object.assign(
        { keyword: keyword.value || '' },
        {
          region: bbSValue.value.areaList.join(','),
          releaseDateType: bbSValue.value.releaseTimeList,
          sort: bbSValue.value.industryList,
          classCode: bbSValue.value.typeList
        },
        { paginate: JSON.parse(JSON.stringify(bbPage.value)) }
      )
      tenderSearch(params).then((res: any) => {
        if (res.data) {
          bbSearchTotal.value = res.data.total
          bbList.value = res.data.rows
          setKeywordSearch()
        }
      })
    }

    const replaceUrl = () => {
      const { query } = route
      router.replace({
        name: 'searchResult',
        query: Object.assign({}, query, {
          keyword: keyword.value,
          type: activeName.value
        })
      })
    }

    const replaceUrlToSearch = () => {
      if (activeName.value === '1') {
        page.value.currentPageNum = 1
      } else {
        bbPage.value.currentPageNum = 1
      }
      replaceUrl()
    }

    const keywordSearchFn = () => {
      replaceUrlToSearch()
    }

    const pageResetChange = () => {
      if (activeName.value === '1') {
        page.value.currentPageNum = 1
        searchFn()
      } else {
        bbPage.value.currentPageNum = 1
        tenderSearchFn()
      }
    }

    // 清空搜索
    const clearSearchFn = () => {
      if (activeName.value === '1') {
        zcCheckList.value = []
        typeCheckList.value = []
        productCheckList.value = []
      } else {
        bbSValue.value.areaList = []
        bbSValue.value.industryList = ''
        bbSValue.value.typeList = ''
        bbSValue.value.releaseTimeList = ''
      }
      searchFn()
      tenderSearchFn()
    }

    const tabChange = (aName?: string) => {
      if (aName) {
        activeName.value = aName
        replaceUrl()
        return
      }
      // replaceUrl()
      searchFn()
      tenderSearchFn()
    }

    const subTabChange = () => {
      // if (aName) {
      //   subActiveName.value = aName
      //   replaceUrl()
      //   return
      // }
      // replaceUrl()
      page.value = {
        page: 1,
        pageSize: 10
      }
      searchFn()
      // tenderSearchFn()
    }

    const paginationSearchFn = () => {
      if (activeName.value === '1') {
        searchFn()
      } else {
        tenderSearchFn()
      }
    }

    const hotListSearch = () => {
      heat({ type: '2' }).then((res: any) => {
        if (res.data) {
          hotList.value = res.data
        }
      })
    }
    // 根据切换的选项卡切换显示数据
    const showList = computed(() => {
      if (activeName.value === '1') {
        return zcqyList.value || []
      } else {
        return bbList.value || []
      }
    })

    const initParams = (params?: any) => {
      // 获取参数
      if (useRoute() || params) {
        const { keyword: keywordParams = '', type: typeParams = '1' } =
          params || useRoute().query
        keyword.value = (keywordParams as string) || ''

        if (
          !typeParams ||
          typeof typeParams !== 'string' ||
          ['1', '2'].indexOf(typeParams) < 0
        ) {
          activeName.value = '1'
        } else {
          activeName.value = (typeParams as string) || '1'
        }
      }
    }

    const loginTipFn = () => {
      if (store.getters) {
        if (!store.getters.loginStatus) {
          store.commit('changeLoginTipsStatus', {
            status: true
          })
        }
      }
    }

    const toDetail = (pid: string, type: string) => {
      const path = '/detail'
      router.push({ path, query: { pid, type } })
    }

    const toDetail2 = (pid: string) => {
      const path = '/bidDetail'
      router.push({ path, query: { uid: pid } })
    }

    onBeforeRouteUpdate((to, from) => {
      if (from.path === to.path) {
        initParams(to.query)
        tabChange()
      }
    })

    onBeforeRouteLeave((to, from, next) => {
      if (['/detail', '/bidDetail'].indexOf(to.path) < 0) {
        store.commit('addKeepAliveInclude', 'searchResult')
        nextTick(() => {
          store.commit('removeKeepAliveInclude', 'searchResult')
        })
      } else {
        store.commit('removeKeepAliveInclude', 'searchResult')
      }
      next()
    })

    onActivated(() => {
      initParams()
      tabChange()
    })

    onBeforeMount(async () => {
      // initParams()
      // searchFn()
      // tenderSearchFn()
      loginTipFn()

      hotListSearch()
      searchLabel().then((res: any) => {
        if (res.data) {
          res.data.area_list = res.data.area_list.map((item: any) => {
            // if (['国家部委', '广东省', '深圳市'].indexOf(item.org_area) < 0) {
            //   item.org_area += '区'
            // }
            // if (item.org_area === '大鹏区') {
            //   item.org_area = '大鹏新区'
            // }
            return item
          })
          zcSearchLabel.value = res.data
        }
        // zcSearchLabel.label_list = res.data.label_list
      })
    })

    return {
      zcSearchLabel,
      typeCheckList,
      productCheckList,
      zcCheckList,
      activeName,
      subActiveName,
      tabChange,
      subTabChange,
      searchTotal,
      keyword,
      keywordSearch, // 搜索状态
      keywordSearchFn,
      searchFn,
      zcLabel,
      zcqyList,
      page,
      pageResetChange,
      clearSearchFn,
      isParamsChange,
      bbSearchTotal,
      bbList,
      bbLabel,
      hotList,
      showList,
      bbSLabel,
      bbSValue,
      bbPage,
      toDetail,
      toDetail2,
      paginationSearchFn
    }
  }
})
