
<template>
  <div class="noData">
    <p class="noData-txt">抱歉，暂时没有符合该搜索条件的内容</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return {}
  }
})
</script>

<style lang="scss" scoped>
.noData {
  width: 100%;
}

.noData-txt {
  margin: 150px;
  text-align: center;
}
</style>
